:deep(.ant-spin-nested-loading) {
  padding: 0 0.4rem;
}

.model .table {
  border-top: 0;
}

.th1 {
  text-align: left !important;
  padding-left: 0.3rem;
}

td {
  height: 0.6rem !important;
}

.center {
  text-align: center;
}

.dot {
  cursor: pointer;
}

.dot:hover {
  color: #f6a75c;
}

.tdtitle {
  position: relative;
  padding-left: 0.1rem;
}

.tdtitle::before {
  content: '';
  width: 2PX;
  height: 2PX;
  background-color: #555555;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}